import styles from "components/sections/curation/BulletsCurationSection.module.scss";
import {useMemo, useState} from "react";
import {ProductCurationSection} from "components/sections/curation/ProductCurationSection";
import {RoundTextButton} from "components/buttons/RoundTextButton";
import {CurationSection} from "components/sections/curation/CurationSection";
import {shuffle} from "common/utils";


export const BulletsCurationSection = (props) => {
    const {curation, location, isAddToPouchVisible, theme} = props;

    const childCurations = useMemo(() => {
        let items = curation.item_list;
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        return items;
    }, [curation]);

    const [currentCuration, setCurrentCuration] = useState(childCurations[0]);

    const content = useMemo(() => {
        return <CurationSection {...props} curation={currentCuration} isTitleHidden/>;
    }, [currentCuration]);

    const handleCategoryButtonClick = (childCuration, index) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'BulletsCurationSectionCategoryClick',
            {
                location,
                curationTitle: curation.title,
                curationDescription: curation.description,
                childCurationTitle: childCuration.title,
                childCurationDescription: childCuration.description,
                childCurationIndex: index,
            }
        );
        if (childCuration?.max_item_count) {
            childCuration.item_list = childCuration.item_list.slice(0, childCuration.max_item_count);
        }
        setCurrentCuration(childCuration);
    };

    return (
        <div className={styles.sectionContainer}>
            <div className={styles.categoryListContainer}>
                {
                    childCurations.map((curation, idx) => {
                        return <RoundTextButton
                                text={curation.title}
                                theme={theme}
                                handleClick={() => handleCategoryButtonClick(curation, idx)}
                                marginRight={0}
                                isSelected={currentCuration.title === curation.title}
                        />
                    })
                }
            </div>
            {content}
        </div>
    );
};
